@import "normalize.scss";
@import "_variables";
@import "_components";
@import "_utilities.scss";

@import url('https://fonts.googleapis.com/css?family=Barlow:300,400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

a {
    color: $defaultColor;
}

input {
    &:focus {
        outline: 0px !important;
    }
}

body {
    background-color: $bg-override;
    width: 100%;
    height: 100vh;
    min-height: initial !important;
    &.fos-user-light-bg {
        background-color: #ffffff;
        background-image: url('../images/fos-user-light-bg.png');
        background-size: cover;
    }
    &.fos-user-dark-bg {
        background-image: url('../images/fos-user-dark-bg.png');
        background-size: cover;
    }

    .fos-user__are-you-user {
        color: $defaultColor;
        text-align: center;
        font-size: 17px;
        a {
            font-weight: bold;
            text-decoration: underline;
            color: $defaultColor;
            &:hover {
                text-decoration: none;
            }
        }
    }

    .fos-user-content{
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        &-box{
            padding: 10px;
            svg{
                width: 444px;
            }
            .fos-user-content-subtitle{
                width: 620px;
                margin-left: 105px;
                line-height: 34px;
                color: #000250;
                font-weight: bold;
                font-size: 28px;
                letter-spacing: 0;
                color: #000250;
                opacity: 1;
            }
            .fos-register-error-box {
                width: 620px;
                margin-top: 20px;
                margin-left: 105px;
                line-height: 34px;
                border: 1px solid #AB2936;
                color: #AB2936;
                border-radius: 54px;
                padding: 0px 14px;
                font-size: 14px;
                .fa-exclamation-circle {
                    top: 1px;
                    position: relative;
                }
            }
            .fos-buttons-box{
                margin-left: 105px;
                margin-top: 30px;
                text-align: left;
            }
            .fos-register-button{
                a{
                    margin-right: 50px;
                    border-radius: 46px;
                    color: white;
                    text-align: center;
                    font: {
                        size: 20px;
                        weight: bold;
                    }
                    letter-spacing: 0;
                    color: #FFFFFF;
                    opacity: 1;
                    padding: 10px 50px;
                    background: transparent linear-gradient(103deg, #00FFFF 0%, #000250 100%) 0% 0% no-repeat padding-box;
                }
            }
            .fos-login-button{
                a{
                    text-align: center;
                    border-bottom: 2px solid #00CCDB;
                    font: {
                        size: 20px;
                        weight: bold;
                    }
                    letter-spacing: 0;
                    color: #00CCDB;
                }
            }
        }
    }
}

.fos_user_registration_register {
    width: 398px !important;
    .form-group {
        width: 80%;
        margin: 0 auto;
    }
    .fos_user_registration_submit-box {
        margin: 0 auto;
        width: 80%;
    }
    input[type="submit"] {
        border-radius: 46px;
        width: 100%;
        margin: 20px auto;
        border: 0px;
        color: white;
        text-align: center;
        font: {
            size: 20px;
            weight: bold;
        }
        letter-spacing: 0;
        color: #FFFFFF;
        opacity: 1;
        padding: 10px 50px;
        background: transparent linear-gradient(103deg, #00FFFF 0%, #000250 100%) 0% 0% no-repeat padding-box;
    }
}

#wrapper {
    overflow-y: scroll !important;
}

#sidebar-menu > ul{
    &.metismenu > li > a{
        color: #000250;
        font-weight: $semiBold;
        font-size: 20px;
        line-height: 24px;
        padding: 16px 20px;
        & i{
            color: #00CCDB;
        }
    }
    &.metismenu li {
        &.active {
            background: #000250 0% 0% no-repeat padding-box;
            border-radius: 0px 37px 37px 0px;
            color: white;
            a {
                color: white;
            }
        }
    }
    li > a i {
        margin-top: 3px;
    }
}

.enlarged {
    .sidebar-settings-menu {
        text-align: left !important;
    }
}

.sidebar-settings-menu {
    margin-top: 70%;
    width: 100%;
    text-align: center;
    a{
        i{
            vertical-align: -6px;
            font-size: 34px;
            line-height: 17px;
            color: #101010 !important;
            margin-right: 3px;
        }
        span{
            font-weight: 600;
            font-size: 17px;
            line-height: 20px;
            color: #101010 !important;
        }
    }
    &.active a {
        i, span {
            color: white !important;
        }
    }
}

.notification-list .noti-icon {
    font-size: 28px;
    color: #000250;
}

.notification-list .noti-icon-badge{
    color: #000250;
    background-color: #00ccdb;
    width: 18px;
    height: 18px;
    line-height: 12px;
    font-size: 12px;
}

.navbar-custom-light .topnav-menu .nav-link {
    color: #000250;
    font-size: 17px;
    font-weight: bold;
    .mdi-chevron-down {
        -webkit-text-stroke: 2px;
        stroke: 2px;
    }
}

input[type="text"], input[type="password"], input[type="email"], input[type="number"] {
    border: 0px;
    border-bottom: 0.5px solid #2D2D2D;
    border-radius: 0px !important;
    padding: 0px;
    height: calc(1.5em) !important;
}

input[type="file"] {
    border: 0px;
    border-bottom: 0.5px solid #2D2D2D;
}

.custom-file-label {
    border: 0px;
    width: 100%;
    cursor: pointer;
    border-radius: 0px !important;
    padding: 0px;
    &::after{
        background-color: $primaryColor;
        color: white;
        content: "Subir archivo";
        font-size: 18px;
        left: 0;
        right: initial;
    }
}

.file-input {
    .form-group {
        margin-bottom: 5px;
    }
    fieldset {
        width: 200px;
    }
    .input-file-label {
        margin-top: 5px;
        line-height: 36px;
    }
}

select {
    border: 0px !important;
    border-bottom: 0.5px solid #2D2D2D !important;
    border-radius: 0px !important;
    &.form-control {
        padding: 0px !important;
        height: calc(1.5em) !important;
    }
}

.right-bar {
    width: 550px !important;
    right: -550px;
    -webkit-transition: all .4s ease-out;
    transition: all .4s ease-out;
    background-color: white;
    .right-bar-toggle {
        position: absolute;
        top: 50px;
        right: 59px;
        background-color: transparent !important;
        width: 47px !important;
        height: 47px !important;
        color: #00CCDB;
        font-size: 4em;
        z-index: 99999;
    }
    .slimScrollDiv {
        padding: 0px !important;
        margin: 0px !important;
    }
    .slimscroll-menu{
        display: flex;
        align-items: center;
        justify-content: center;
        .slimscroll-box {
            display: flex;
            flex-direction: column;
            .fos-user-login-title {
                color: #000250;
                text-align: center;
                font-weight: bold;
                font-size: 33px;
                margin-bottom: 18px;
            }
            .fos-user-register-now {
                display: flex;
                justify-content: center;
                margin-bottom: 31px;
                a {
                    color: #13133B;
                }
                .fos-user__not-user-text {
                    color: #2D2D2D;
                    font-weight: 300;
                    font-size: 17px;
                    margin-right: 5px;
                }
                .fos-user__register-now {
                    color: #13133B;
                    font-size: 17px;
                    text-decoration: underline;
                    font-weight: bold;
                }
            }
            .fos-user__sidebar-login {
                display: flex;
                align-items: center;
                flex-direction: column;
                input{
                    width: 296px;
                    padding: 8px 0px;
                    margin: 0 auto;
                    font-size: 18px;
                    color: #929292;
                    text-align: center;
                    &:first{
                        margin-bottom: 26px;
                    }
                }
                .fos-user__username-input{
                    margin-bottom: 14px;
                }
            }
            .fos-user__forgot-password{
                text-align: center;
                text-decoration: underline;
                padding-top: 7px;
                margin-bottom: 31px;
                font-weight: bold;
                font-size: 15px;
                line-height: 18px;
                letter-spacing: 0;
                color: #13133B;
                opacity: 1;
                color: $defaultColor;
                a {
                    color: $defaultColor !important;
                }
            }
        }
    }
}

.fos-user__social-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    .fos-user__social_text {
        text-align: center;
        font-weight: 300;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0;
        color: #929292;
    }
    .fos-user__social-icons {
        display: flex;
        justify-content: center;
        margin-top: 5px;
        .fos-user__social_icon {
            width: 28px;
            height: 28px;
            border-radius: 28px;
            background-color: #D1D1D1;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 3px;
            svg{
                width: 16px;
                height: 16px;
                fill: #929292;
            }
        }
    }
}
.fos-user__accept-tos-box {
    width: 341px;
    margin: 20px auto;
    text-align: center;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0;
    color: #929292;
    a {
        color: $primaryColor;
    }
}
.fos-user__language-switcher {
    margin-top: 20px;
    text-align: center;
    a {
        color: #2D2D2D;
    }
}
.fos-user__submit-button {
    border: 1px solid #00CCDB;
    border-radius: 46px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0;
    color: #00CCDB !important;
    background-color: transparent;
    padding: 13px 117px;
}

.logo-box .logo img {
    vertical-align: top;
}

.left-side-menu {
    box-shadow: none;
    background-color: $bg-override;

    #sidebar-menu #side-menu {
        a {
            font-size: 20px;
            i {
                font-size: 25px;
            }
        }
    }
}

.navbar-custom-shadow {
    box-shadow: 0px 3px 6px #00000029;
}

.content-page {
    background-color: $bg-override;
}

.footer {
    background-color: $bg-override;
}

.card-box {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 4px 3px 17px #00000017;
    border-radius: 10px;
}

.content{
    padding-top: 30px;
}

.page-title-box {
    margin-bottom: 20px;
}

.page-title-box-notification {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.delete-all-notifications {
    height: 40px;
}

.page-title {
    text-align: left;
    font-weight: bold;
    font-size: 35px !important;
    line-height: 42px !important;
    letter-spacing: 0;
    color: #101010 !important;
}

.form-group {
    label {
        color: $secondaryColor;
        font-size: 16px;
        margin: 0px;
    }
    .form-check {
        margin-left: 17px;
    }
}

#settings {
    .form-group {
        label {
            font-size: 16px;
        }
    }
}

.filters, .order-filter {
    .form-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        label {
            color: #101010;
            font-weight: $semiBold;
            padding-right: 20px;
        }
    }
}

.order-filter {
    .form-group {
        margin-right: 20px;
    }
}

.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
    background: $primaryColor 0% 0% no-repeat padding-box;
    border-radius: 25px;
    border: 0px !important;
    &:active {
        background-color: $defaultColor !important;
        border: 0px !important;
    }
    &:focus {
        border: 0px !important;
        box-shadow: 0px !important;
    }
}

.file-steps_fake_line {
    width: 86%;
    position: relative;
    margin-top: 10px;
    top: 21.5px;
    left: 21px;
    height: 2px;
    background-color: #929292;
}

.file_step {
    font-size: 26px;
    font-weight: bold;
    text-align: center;
    background-color: white;
    color: #929292;
    border: 2px #929292 solid;
    width: 43px;
    height: 43px;
    border-radius: 43px;
    &.selected {
        border-color: $primaryColor;
        color: $defaultColor;
    }
    &.done {
        color: $primaryColor;
        background-color: $defaultColor;
        border-color: $defaultColor;
    }
}

.task-grid-box {
    border-radius: 1em/5em;
}

.task-grid {
    height: 160px;
    &.card-box {
        padding: 0px;
        margin: 1.5rem;
    }
    .row {
        padding: 0px;
    }
}

.task-grid .task_title,
.task-grid .task_description {
    padding-left: 20px;
}

.task_price {
    font-size: 28px;
    font-weight: bold;
    line-height: 90px;
    height: 100%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 160px;
    .task_euro {
        font-size: 18px;
        vertical-align: text-bottom;
    }
}

.task {
    padding: 1rem;
    &_title {
        font-size: 21px;
        font-weight: bold;
        color: $defaultColor;
    }
    &_description {
        font-size: 15px;
        line-height: 17px;
        letter-spacing: 0;
        color: #5A5A5A;
        opacity: 1;
    }
}

.profile-image-container {
    display: flex;
    margin-bottom: 30px;
    .profile-image-box {
        margin-right: 30px;
    }
    .profile-image-actions {
        display: flex;
        flex-direction: column;
        .profile-label-change {
            cursor: pointer;
            color: $defaultColor;
            font-size: 15px;
        }
    }
}

.year_filter {
    .form-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0px;
        label {
            margin-right: 10px;
        }
        select {
            font-size: 35px;
            color: $defaultColor;
        }
    }
}

.create_file_box_link {
    color: $defaultColor;
    line-height: 3em;
}

.create_file_box_icon {
    margin-right: 0px;
}

.notification-item {
    height: 50px;
    .notification-date {
        background-color: $primaryColor;
        color: white;
        padding: 3px 15px;
        border-radius: 25px;
    }
    .notification-text {
        text-align: left;
        font-weight: $semiBold;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: 0;
        color: #101010;
        opacity: 1;
        a {
            color: #6658dd;
        }
    }
    .notification-actions {
        text-align: right;
        font-size: 25px;
        i {
            color: #101010;
            width: 35px;
            height: 35px;
            line-height: 35px;
            border-radius: 35px;
            background-color: #F2F2F2;
            text-align: center;
        }
    }
}

.sub-logo-text {
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0;
    color: $defaultColor !important;
    opacity: 1;
}

.fos_user_registration_register {
    width: 80%;
    margin: 0 auto;
    .form-group {
        .form-check {
            input[type="checkbox"] {
                margin-bottom:2px;
            }
            label {
                &::before {
                    top: 1px !important;
                    border: 1px solid $defaultColor !important;
                    border-radius: 0px;
                }
                a {
                    font-weight: 700;
                    text-decoration: underline;
                    color: #000250;
                }
                font-size: 14px;
            }
        }
    }
}

.form_error_box {
    color: #AB2936;
    border-radius: 54px;
    padding: 14px 14px;
    font-size: 14px;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
    border-color: #AB2936;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ab2936' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23ab2936' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
}

.flash-success {
    padding: 30px 20px;
    text-align: center;
    color: #000250;
}

span {
    img {
        padding-bottom: 20px;
    }
}

.logo {
    width: 80%;
    margin: 0 auto;
}

.sidebar-logos {
    margin-top: 70%;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
    margin-left: 5%;
    .sidebar-logo {
        flex-basis: 100%;
        margin-bottom: 20px;
        img {
            max-width: 100%;
            width: 100%;
        }
    }
}

.fos-user-reseting {
    .fos-user-content {
        .form-group {
            width: 390px;
            display: flex;
            justify-content: center;
            input {
                width: 80%;
                margin: 0 auto;
            }
            input[type="submit"] {
                border-radius: 46px;
                width: 80%;
                margin: 20px auto;
                border: 0px;
                color: white;
                text-align: center;
                font: {
                    size: 20px;
                    weight: bold;
                }
                letter-spacing: 0;
                color: #FFFFFF;
                opacity: 1;
                padding: 10px 50px;
                background: transparent linear-gradient(103deg, #00FFFF 0%, #000250 100%) 0% 0% no-repeat padding-box;
            }
        }
    }
}

.fos-user-reseting-box.reset {
    input[type="password"] {
        width: 80%;
        margin: 0 auto;
    }
}

.dashboard-summary-title-box {
    .form-section-title {
        font-family: $secondaryFontFamily;
    }

    .underline {
        border: 1px solid #C8C8C8;
        width: 100%;
    }
}
table.dashboard-casefile tr {
    font-size: 16px;
    color: #101010;
    td, th {
        padding: 0.6rem 0.85rem;
    }
    td:nth-child(1), td:nth-child(3) {
        font-weight: $semiBold;
    }
    td:nth-child(4) a {
        font-size: 15px;
        text-decoration: underline;
    }
}
footer {
    a {
        font-size: 15px;
        line-height: 15px;
        letter-spacing: 0;
        color: #929292;
        &:hover {
            color: $primaryColor;
        }
    }
}

body.digital-funer-box .datepicker table tr td.active.day {
    background-color: $primaryColor !important;
}

body.case-file.update {
    .accordion .card {
        box-shadow: none;
    }
    .card-header {
        border-bottom: 1px solid black;
        background-color: transparent;
        .accordion-link {
            font-weight: bold;
            &::after {
                float: right;
                cursor: pointer;
                font-family: 'feather';
                content: '\e842';
                color: black;
            }
            &.collapsed::after {
                content: "\e845";
            }
        }
    }
}

.jq-toast-wrap.top-right {
    top: 80px;
    right: 25px;
    .jq-icon-success {
        background-color: #00ccdb;
    }
}

.task-check {
  color: #00CCDB;
}

.task-not-applicable {
    color: #f1556c;
}

.profile-image-upload {
    .custom-file-label {
        &::after{
            content: "Cambiar foto";
        }
    }
    fieldset {
        margin-bottom: 0px;
    }
}

#delete_account_reason_reason .form-check:not(:last-child) {
    margin-bottom: 10px;
}

.delete-account-link {
    color: #f1556c;
}

.case-file-form-step.create-customer-data label.required:after,
.case-file-form-step.create-payment .required label:after,
.case-file-form-step.create-payment .required label:after,
.profile label.required.iban-label:after {
    content: '*';
    color: #f1556c;
    padding-left: 5px;
}

.form-group textarea {
    resize: none;
}

.prototype {
    display: none !important;
}

.create-task__cart {
    margin-top: 30px;
    margin-left: 24px;
    &.step3 {
        margin-left: 0px;
    }
    &-title {
        color: $secondaryColor;
        font-size: 18px;
        font-weight: bold;
    }
    &-icon {
        color: $defaultColor;
        float: left;
        vertical-align: middle;
    }
    &-total {
        color: $defaultColor;
        font-family: "Barlow";
        font-weight: bold;
        font-size: 21px;
    }
    &-without-tax {
        font-size: 14px;
        color: #929292;
    }
    &-price-base {
        font-size: 20px;
    }
    &-taxes {
        font-size: 20px;
    }
    &-price {
        font-size: 45px;
        line-height: 42px;
        font-weight: 600;
        font-family: "Barlow";
        color: $primaryColor;
        &-step3 {
            font-size: 32px;
            color: $primaryColor;
            font-family: "Barlow";
            line-height: 42px;
            font-weight: 600;
            font-family: "Barlow";
            color: $primaryColor;
        }
    }
    &-currency {
        font-size: 35px;
        color: $primaryColor;
    }
    &-item {
        display: flex;
        justify-content: space-between;
        &-not-selected, &-title, &-currency {
            font-size: 16px;
            color: #5A5A5A;
        }
    }
}

.icon-help {
    margin-left: 10px;
    outline: none;
}

.payment-label {
    display: block;
}

.task-resume-block {
    .total {
        font-size: 20px;
        color: #000250;
        font-weight: 600;
        line-height: initial;
    }
    .stats-label {
        margin-left: 5px;
        line-height: initial;
    }
}

.bold {
    font-weight: 700;
}
.stats-number {
    font-size: 45px;
    color: $defaultColor;
    font-weight: bold;
    line-height: 54px;
}

.stats-icon {
    color: $primaryColor;
    font-size: 37px;
    margin-right: 19px;
}

.stats-label {
    margin-left: 19px;
    color: $secondaryColor;
    line-height: 54px;
    font-weight: $semiBold;
}

.btn-padding-large{
    padding: 5px 30px;
}

.page-subtitle {
    color: $primaryColor;
    font-size: 26px;
    font-family: $secondaryFontFamily;
}

.form-section-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0;
    color: $secondaryColor;
}

.profile-image {
    width: 138px;
    height: 138px;
    border-radius: 138px;
}
.flex__centered{
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex__between{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex__column {
    flex-direction: column;
}

.cursor-default {
    cursor: default;
}
::-webkit-input-placeholder {
    text-align: center;
}
:-moz-placeholder { /* Firefox 18- */
    text-align: center;
}
::-moz-placeholder {  /* Firefox 19+ */
   text-align: center;
}

:-ms-input-placeholder {
   text-align: center;
}
@import url("https://fonts.googleapis.com/css?family=Barlow:300,400,600,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
::-webkit-input-placeholder {
  text-align: center; }

:-moz-placeholder {
  /* Firefox 18- */
  text-align: center; }

::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center; }

:-ms-input-placeholder {
  text-align: center; }

.stats-number {
  font-size: 45px;
  color: #000250;
  font-weight: bold;
  line-height: 54px; }

.stats-icon {
  color: #00CCDB;
  font-size: 37px;
  margin-right: 19px; }

.stats-label {
  margin-left: 19px;
  color: #101010;
  line-height: 54px;
  font-weight: 600; }

.btn-padding-large {
  padding: 5px 30px; }

.page-subtitle {
  color: #00CCDB;
  font-size: 26px;
  font-family: "Lato"; }

.form-section-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0;
  color: #101010; }

.profile-image {
  width: 138px;
  height: 138px;
  border-radius: 138px; }

.flex__centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.flex__between {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.flex__column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

.cursor-default {
  cursor: default; }

a {
  color: #000250; }

input:focus {
  outline: 0px !important; }

body {
  background-color: #F9F9F9;
  width: 100%;
  height: 100vh;
  min-height: initial !important; }
  body.fos-user-light-bg {
    background-color: #ffffff;
    background-image: url("../images/fos-user-light-bg.png");
    background-size: cover; }
  body.fos-user-dark-bg {
    background-image: url("../images/fos-user-dark-bg.png");
    background-size: cover; }
  body .fos-user__are-you-user {
    color: #000250;
    text-align: center;
    font-size: 17px; }
    body .fos-user__are-you-user a {
      font-weight: bold;
      text-decoration: underline;
      color: #000250; }
      body .fos-user__are-you-user a:hover {
        text-decoration: none; }
  body .fos-user-content {
    width: 100%;
    height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    body .fos-user-content-box {
      padding: 10px; }
      body .fos-user-content-box svg {
        width: 444px; }
      body .fos-user-content-box .fos-user-content-subtitle {
        width: 620px;
        margin-left: 105px;
        line-height: 34px;
        color: #000250;
        font-weight: bold;
        font-size: 28px;
        letter-spacing: 0;
        color: #000250;
        opacity: 1; }
      body .fos-user-content-box .fos-register-error-box {
        width: 620px;
        margin-top: 20px;
        margin-left: 105px;
        line-height: 34px;
        border: 1px solid #AB2936;
        color: #AB2936;
        border-radius: 54px;
        padding: 0px 14px;
        font-size: 14px; }
        body .fos-user-content-box .fos-register-error-box .fa-exclamation-circle {
          top: 1px;
          position: relative; }
      body .fos-user-content-box .fos-buttons-box {
        margin-left: 105px;
        margin-top: 30px;
        text-align: left; }
      body .fos-user-content-box .fos-register-button a {
        margin-right: 50px;
        border-radius: 46px;
        color: white;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0;
        color: #FFFFFF;
        opacity: 1;
        padding: 10px 50px;
        background: transparent linear-gradient(103deg, #00FFFF 0%, #000250 100%) 0% 0% no-repeat padding-box; }
      body .fos-user-content-box .fos-login-button a {
        text-align: center;
        border-bottom: 2px solid #00CCDB;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0;
        color: #00CCDB; }

.fos_user_registration_register {
  width: 398px !important; }
  .fos_user_registration_register .form-group {
    width: 80%;
    margin: 0 auto; }
  .fos_user_registration_register .fos_user_registration_submit-box {
    margin: 0 auto;
    width: 80%; }
  .fos_user_registration_register input[type="submit"] {
    border-radius: 46px;
    width: 100%;
    margin: 20px auto;
    border: 0px;
    color: white;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
    padding: 10px 50px;
    background: transparent linear-gradient(103deg, #00FFFF 0%, #000250 100%) 0% 0% no-repeat padding-box; }

#wrapper {
  overflow-y: scroll !important; }

#sidebar-menu > ul.metismenu > li > a {
  color: #000250;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  padding: 16px 20px; }
  #sidebar-menu > ul.metismenu > li > a i {
    color: #00CCDB; }

#sidebar-menu > ul.metismenu li.active {
  background: #000250 0% 0% no-repeat padding-box;
  border-radius: 0px 37px 37px 0px;
  color: white; }
  #sidebar-menu > ul.metismenu li.active a {
    color: white; }

#sidebar-menu > ul li > a i {
  margin-top: 3px; }

.enlarged .sidebar-settings-menu {
  text-align: left !important; }

.sidebar-settings-menu {
  margin-top: 70%;
  width: 100%;
  text-align: center; }
  .sidebar-settings-menu a i {
    vertical-align: -6px;
    font-size: 34px;
    line-height: 17px;
    color: #101010 !important;
    margin-right: 3px; }
  .sidebar-settings-menu a span {
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    color: #101010 !important; }
  .sidebar-settings-menu.active a i, .sidebar-settings-menu.active a span {
    color: white !important; }

.notification-list .noti-icon {
  font-size: 28px;
  color: #000250; }

.notification-list .noti-icon-badge {
  color: #000250;
  background-color: #00ccdb;
  width: 18px;
  height: 18px;
  line-height: 12px;
  font-size: 12px; }

.navbar-custom-light .topnav-menu .nav-link {
  color: #000250;
  font-size: 17px;
  font-weight: bold; }
  .navbar-custom-light .topnav-menu .nav-link .mdi-chevron-down {
    -webkit-text-stroke: 2px;
    stroke: 2px; }

input[type="text"], input[type="password"], input[type="email"], input[type="number"] {
  border: 0px;
  border-bottom: 0.5px solid #2D2D2D;
  border-radius: 0px !important;
  padding: 0px;
  height: calc(1.5em) !important; }

input[type="file"] {
  border: 0px;
  border-bottom: 0.5px solid #2D2D2D; }

.custom-file-label {
  border: 0px;
  width: 100%;
  cursor: pointer;
  border-radius: 0px !important;
  padding: 0px; }
  .custom-file-label::after {
    background-color: #00CCDB;
    color: white;
    content: "Subir archivo";
    font-size: 18px;
    left: 0;
    right: initial; }

.file-input .form-group {
  margin-bottom: 5px; }

.file-input fieldset {
  width: 200px; }

.file-input .input-file-label {
  margin-top: 5px;
  line-height: 36px; }

select {
  border: 0px !important;
  border-bottom: 0.5px solid #2D2D2D !important;
  border-radius: 0px !important; }
  select.form-control {
    padding: 0px !important;
    height: calc(1.5em) !important; }

.right-bar {
  width: 550px !important;
  right: -550px;
  -webkit-transition: all .4s ease-out;
  transition: all .4s ease-out;
  background-color: white; }
  .right-bar .right-bar-toggle {
    position: absolute;
    top: 50px;
    right: 59px;
    background-color: transparent !important;
    width: 47px !important;
    height: 47px !important;
    color: #00CCDB;
    font-size: 4em;
    z-index: 99999; }
  .right-bar .slimScrollDiv {
    padding: 0px !important;
    margin: 0px !important; }
  .right-bar .slimscroll-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .right-bar .slimscroll-menu .slimscroll-box {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; }
      .right-bar .slimscroll-menu .slimscroll-box .fos-user-login-title {
        color: #000250;
        text-align: center;
        font-weight: bold;
        font-size: 33px;
        margin-bottom: 18px; }
      .right-bar .slimscroll-menu .slimscroll-box .fos-user-register-now {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        margin-bottom: 31px; }
        .right-bar .slimscroll-menu .slimscroll-box .fos-user-register-now a {
          color: #13133B; }
        .right-bar .slimscroll-menu .slimscroll-box .fos-user-register-now .fos-user__not-user-text {
          color: #2D2D2D;
          font-weight: 300;
          font-size: 17px;
          margin-right: 5px; }
        .right-bar .slimscroll-menu .slimscroll-box .fos-user-register-now .fos-user__register-now {
          color: #13133B;
          font-size: 17px;
          text-decoration: underline;
          font-weight: bold; }
      .right-bar .slimscroll-menu .slimscroll-box .fos-user__sidebar-login {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; }
        .right-bar .slimscroll-menu .slimscroll-box .fos-user__sidebar-login input {
          width: 296px;
          padding: 8px 0px;
          margin: 0 auto;
          font-size: 18px;
          color: #929292;
          text-align: center; }
          .right-bar .slimscroll-menu .slimscroll-box .fos-user__sidebar-login input:first {
            margin-bottom: 26px; }
        .right-bar .slimscroll-menu .slimscroll-box .fos-user__sidebar-login .fos-user__username-input {
          margin-bottom: 14px; }
      .right-bar .slimscroll-menu .slimscroll-box .fos-user__forgot-password {
        text-align: center;
        text-decoration: underline;
        padding-top: 7px;
        margin-bottom: 31px;
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0;
        color: #13133B;
        opacity: 1;
        color: #000250; }
        .right-bar .slimscroll-menu .slimscroll-box .fos-user__forgot-password a {
          color: #000250 !important; }

.fos-user__social-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 10px; }
  .fos-user__social-box .fos-user__social_text {
    text-align: center;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0;
    color: #929292; }
  .fos-user__social-box .fos-user__social-icons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-top: 5px; }
    .fos-user__social-box .fos-user__social-icons .fos-user__social_icon {
      width: 28px;
      height: 28px;
      border-radius: 28px;
      background-color: #D1D1D1;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      margin-right: 3px; }
      .fos-user__social-box .fos-user__social-icons .fos-user__social_icon svg {
        width: 16px;
        height: 16px;
        fill: #929292; }

.fos-user__accept-tos-box {
  width: 341px;
  margin: 20px auto;
  text-align: center;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0;
  color: #929292; }
  .fos-user__accept-tos-box a {
    color: #00CCDB; }

.fos-user__language-switcher {
  margin-top: 20px;
  text-align: center; }
  .fos-user__language-switcher a {
    color: #2D2D2D; }

.fos-user__submit-button {
  border: 1px solid #00CCDB;
  border-radius: 46px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0;
  color: #00CCDB !important;
  background-color: transparent;
  padding: 13px 117px; }

.logo-box .logo img {
  vertical-align: top; }

.left-side-menu {
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: #F9F9F9; }
  .left-side-menu #sidebar-menu #side-menu a {
    font-size: 20px; }
    .left-side-menu #sidebar-menu #side-menu a i {
      font-size: 25px; }

.navbar-custom-shadow {
  -webkit-box-shadow: 0px 3px 6px #00000029;
          box-shadow: 0px 3px 6px #00000029; }

.content-page {
  background-color: #F9F9F9; }

.footer {
  background-color: #F9F9F9; }

.card-box {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  -webkit-box-shadow: 4px 3px 17px #00000017;
          box-shadow: 4px 3px 17px #00000017;
  border-radius: 10px; }

.content {
  padding-top: 30px; }

.page-title-box {
  margin-bottom: 20px; }

.page-title-box-notification {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.delete-all-notifications {
  height: 40px; }

.page-title {
  text-align: left;
  font-weight: bold;
  font-size: 35px !important;
  line-height: 42px !important;
  letter-spacing: 0;
  color: #101010 !important; }

.form-group label {
  color: #101010;
  font-size: 16px;
  margin: 0px; }

.form-group .form-check {
  margin-left: 17px; }

#settings .form-group label {
  font-size: 16px; }

.filters .form-group, .order-filter .form-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .filters .form-group label, .order-filter .form-group label {
    color: #101010;
    font-weight: 600;
    padding-right: 20px; }

.order-filter .form-group {
  margin-right: 20px; }

.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
  background: #00CCDB 0% 0% no-repeat padding-box;
  border-radius: 25px;
  border: 0px !important; }
  .btn-primary:active, .btn-primary:hover:active, .btn-primary:active:active, .btn-primary:visited:active {
    background-color: #000250 !important;
    border: 0px !important; }
  .btn-primary:focus, .btn-primary:hover:focus, .btn-primary:active:focus, .btn-primary:visited:focus {
    border: 0px !important;
    -webkit-box-shadow: 0px !important;
            box-shadow: 0px !important; }

.file-steps_fake_line {
  width: 86%;
  position: relative;
  margin-top: 10px;
  top: 21.5px;
  left: 21px;
  height: 2px;
  background-color: #929292; }

.file_step {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  background-color: white;
  color: #929292;
  border: 2px #929292 solid;
  width: 43px;
  height: 43px;
  border-radius: 43px; }
  .file_step.selected {
    border-color: #00CCDB;
    color: #000250; }
  .file_step.done {
    color: #00CCDB;
    background-color: #000250;
    border-color: #000250; }

.task-grid-box {
  border-radius: 1em/5em; }

.task-grid {
  height: 160px; }
  .task-grid.card-box {
    padding: 0px;
    margin: 1.5rem; }
  .task-grid .row {
    padding: 0px; }

.task-grid .task_title,
.task-grid .task_description {
  padding-left: 20px; }

.task_price {
  font-size: 28px;
  font-weight: bold;
  line-height: 90px;
  height: 100%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  height: 160px; }
  .task_price .task_euro {
    font-size: 18px;
    vertical-align: text-bottom; }

.task {
  padding: 1rem; }
  .task_title {
    font-size: 21px;
    font-weight: bold;
    color: #000250; }
  .task_description {
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0;
    color: #5A5A5A;
    opacity: 1; }

.profile-image-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px; }
  .profile-image-container .profile-image-box {
    margin-right: 30px; }
  .profile-image-container .profile-image-actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    .profile-image-container .profile-image-actions .profile-label-change {
      cursor: pointer;
      color: #000250;
      font-size: 15px; }

.year_filter .form-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0px; }
  .year_filter .form-group label {
    margin-right: 10px; }
  .year_filter .form-group select {
    font-size: 35px;
    color: #000250; }

.create_file_box_link {
  color: #000250;
  line-height: 3em; }

.create_file_box_icon {
  margin-right: 0px; }

.notification-item {
  height: 50px; }
  .notification-item .notification-date {
    background-color: #00CCDB;
    color: white;
    padding: 3px 15px;
    border-radius: 25px; }
  .notification-item .notification-text {
    text-align: left;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0;
    color: #101010;
    opacity: 1; }
    .notification-item .notification-text a {
      color: #6658dd; }
  .notification-item .notification-actions {
    text-align: right;
    font-size: 25px; }
    .notification-item .notification-actions i {
      color: #101010;
      width: 35px;
      height: 35px;
      line-height: 35px;
      border-radius: 35px;
      background-color: #F2F2F2;
      text-align: center; }

.sub-logo-text {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0;
  color: #000250 !important;
  opacity: 1; }

.fos_user_registration_register {
  width: 80%;
  margin: 0 auto; }
  .fos_user_registration_register .form-group .form-check input[type="checkbox"] {
    margin-bottom: 2px; }
  .fos_user_registration_register .form-group .form-check label {
    font-size: 14px; }
    .fos_user_registration_register .form-group .form-check label::before {
      top: 1px !important;
      border: 1px solid #000250 !important;
      border-radius: 0px; }
    .fos_user_registration_register .form-group .form-check label a {
      font-weight: 700;
      text-decoration: underline;
      color: #000250; }

.form_error_box {
  color: #AB2936;
  border-radius: 54px;
  padding: 14px 14px;
  font-size: 14px; }

.form-control.is-invalid, .was-validated .form-control:invalid {
  border-color: #AB2936;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ab2936' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23ab2936' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E"); }

.flash-success {
  padding: 30px 20px;
  text-align: center;
  color: #000250; }

span img {
  padding-bottom: 20px; }

.logo {
  width: 80%;
  margin: 0 auto; }

.sidebar-logos {
  margin-top: 70%;
  width: 90%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 5%;
  margin-left: 5%; }
  .sidebar-logos .sidebar-logo {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    margin-bottom: 20px; }
    .sidebar-logos .sidebar-logo img {
      max-width: 100%;
      width: 100%; }

.fos-user-reseting .fos-user-content .form-group {
  width: 390px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .fos-user-reseting .fos-user-content .form-group input {
    width: 80%;
    margin: 0 auto; }
  .fos-user-reseting .fos-user-content .form-group input[type="submit"] {
    border-radius: 46px;
    width: 80%;
    margin: 20px auto;
    border: 0px;
    color: white;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
    padding: 10px 50px;
    background: transparent linear-gradient(103deg, #00FFFF 0%, #000250 100%) 0% 0% no-repeat padding-box; }

.fos-user-reseting-box.reset input[type="password"] {
  width: 80%;
  margin: 0 auto; }

.dashboard-summary-title-box .form-section-title {
  font-family: "Lato"; }

.dashboard-summary-title-box .underline {
  border: 1px solid #C8C8C8;
  width: 100%; }

table.dashboard-casefile tr {
  font-size: 16px;
  color: #101010; }
  table.dashboard-casefile tr td, table.dashboard-casefile tr th {
    padding: 0.6rem 0.85rem; }
  table.dashboard-casefile tr td:nth-child(1), table.dashboard-casefile tr td:nth-child(3) {
    font-weight: 600; }
  table.dashboard-casefile tr td:nth-child(4) a {
    font-size: 15px;
    text-decoration: underline; }

footer a {
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 0;
  color: #929292; }
  footer a:hover {
    color: #00CCDB; }

body.digital-funer-box .datepicker table tr td.active.day {
  background-color: #00CCDB !important; }

body.case-file.update .accordion .card {
  -webkit-box-shadow: none;
          box-shadow: none; }

body.case-file.update .card-header {
  border-bottom: 1px solid black;
  background-color: transparent; }
  body.case-file.update .card-header .accordion-link {
    font-weight: bold; }
    body.case-file.update .card-header .accordion-link::after {
      float: right;
      cursor: pointer;
      font-family: 'feather';
      content: '\e842';
      color: black; }
    body.case-file.update .card-header .accordion-link.collapsed::after {
      content: "\e845"; }

.jq-toast-wrap.top-right {
  top: 80px;
  right: 25px; }
  .jq-toast-wrap.top-right .jq-icon-success {
    background-color: #00ccdb; }

.task-check {
  color: #00CCDB; }

.task-not-applicable {
  color: #f1556c; }

.profile-image-upload .custom-file-label::after {
  content: "Cambiar foto"; }

.profile-image-upload fieldset {
  margin-bottom: 0px; }

#delete_account_reason_reason .form-check:not(:last-child) {
  margin-bottom: 10px; }

.delete-account-link {
  color: #f1556c; }

.case-file-form-step.create-customer-data label.required:after,
.case-file-form-step.create-payment .required label:after,
.case-file-form-step.create-payment .required label:after,
.profile label.required.iban-label:after {
  content: '*';
  color: #f1556c;
  padding-left: 5px; }

.form-group textarea {
  resize: none; }

.prototype {
  display: none !important; }

.create-task__cart {
  margin-top: 30px;
  margin-left: 24px; }
  .create-task__cart.step3 {
    margin-left: 0px; }
  .create-task__cart-title {
    color: #101010;
    font-size: 18px;
    font-weight: bold; }
  .create-task__cart-icon {
    color: #000250;
    float: left;
    vertical-align: middle; }
  .create-task__cart-total {
    color: #000250;
    font-family: "Barlow";
    font-weight: bold;
    font-size: 21px; }
  .create-task__cart-without-tax {
    font-size: 14px;
    color: #929292; }
  .create-task__cart-price-base {
    font-size: 20px; }
  .create-task__cart-taxes {
    font-size: 20px; }
  .create-task__cart-price {
    font-size: 45px;
    line-height: 42px;
    font-weight: 600;
    font-family: "Barlow";
    color: #00CCDB; }
    .create-task__cart-price-step3 {
      font-size: 32px;
      color: #00CCDB;
      font-family: "Barlow";
      line-height: 42px;
      font-weight: 600;
      font-family: "Barlow";
      color: #00CCDB; }
  .create-task__cart-currency {
    font-size: 35px;
    color: #00CCDB; }
  .create-task__cart-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    .create-task__cart-item-not-selected, .create-task__cart-item-title, .create-task__cart-item-currency {
      font-size: 16px;
      color: #5A5A5A; }

.icon-help {
  margin-left: 10px;
  outline: none; }

.payment-label {
  display: block; }

.task-resume-block .total {
  font-size: 20px;
  color: #000250;
  font-weight: 600;
  line-height: initial; }

.task-resume-block .stats-label {
  margin-left: 5px;
  line-height: initial; }

.bold {
  font-weight: 700; }
